//
// Typography
// --------------------------------------------------

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Manrope:wght@200..800&display=swap');

// Cursive (Pacifico)
.fontCursive {
  font-family: 'pacificoregular', cursive;
  font-weight: normal;
}

// Sans serif (Manrope).
.fontLight {
  font-family: 'Manrope', sans-serif;
  font-weight: 300;
}
.fontRegular {
  font-family: 'Manrope', sans-serif;
  font-weight: normal;
}
.fontItalic {
  font-family: 'Manrope', sans-serif;
  font-style: italic;
  font-weight: normal;
}
.fontSemibold {
  font-family: 'Manrope', sans-serif;
  font-weight: 600;
}
.fontBold {
  font-family: 'Manrope', sans-serif;
  font-weight: 700;
}
.fontBoldItalic {
  font-family: 'Manrope', sans-serif;
  font-style: italic;
  font-weight: 700;
}

@font-face {
  font-family: 'pacificoregular';
  src: url('../fonts/pacifico/pacifico-webfont.woff2') format('woff2'),
       url('../fonts/pacifico/pacifico-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

// HEADINGS
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: @color-primary;
  .fontBold;
  line-height: 1.3;
  margin: 0 0 (@base-line-height / 3);

  small,
  .small {
    font-weight: normal;
    line-height: 1;
  }
}

h1, .h1 {
  font-size: 2.0588235294rem;
}
h2, .h2 {
  font-size: 1.5294117647rem;
}
h3, .h3 {
  font-size: 1.4117647059rem;
}
h4, .h4 {
  font-size: 1.1764705882rem;
}
h5, .h5 {
  font-size: 1rem;
}
h6, .h6 {
  font-size: 1rem;
}

// BODY
p {
  color: @text-color;
  .fontRegular;
  font-size: 1rem;
  margin: 0 0 (@base-line-height);
  &.intro {
    .fontBold;
    em {
      .fontBoldItalic;
    }
  }
  &.intro {
    color: @color-secondary;
  }
}

b,
strong {
  .fontBold;
}
em {
  .fontItalic;
}
strong em,
em strong {
  .fontBoldItalic;
}

// LISTS
ul, ol, dl {
  .fontRegular;
  font-size: 1rem;
  margin: 0 0 (@base-line-height);
  ul, ol, dl {
    margin-bottom: 0;
  }
}

// MISC
small,
.small {
  font-size: 80%;
}

mark,
.mark {
  background-color: @color-focus;
  padding: .2em;
}

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-justify {
  text-align: justify;
}
.text-nowrap {
  white-space: nowrap;
}
.text-lowercase {
  text-transform: lowercase;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
