//
// Navigation
// --------------------------------------------------

.main-nav {
  .menu {
    .flex-display(flex);
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
  }
  .menu-item {
    width: 25%;
    &:first-child a {
      border-left: none;
    }
    // &:first-child.is-active a {
    //   // background-color: @white;
    //   background: none;
    // }
    &.is-active a {
      // background-color: @color-primary;
      background: none;
      color: @white;
      i {
        color: inherit;
      }
    }
  }
  a {
    background: rgba(0,0,0,0.3);
    .flex-align-items(center);
    //border-left: 1px solid @color-quinary;
    color: @color-secondary;
    .flex-display(flex);
    height: 60px;
    .flex-justify-content(center);
    text-align: center;
    &:hover {
      // background-color: @color-quinary;
      // color: @white;
      background: rgba(0,0,0,0.6);
      text-decoration: none;
    }
  }
  span {
    display: none;
    .fontBold;
    margin-left: 12px;
  }
  i {
    color: @color-secondary;
    font-size: 1.8rem;
    &.icon-themas {
      font-size: 1.7rem;
    }
  }
  .no-flexbox & {
    .menu {
      display: block;
      .spacing();
      .menu-item {
        .inline-block();
        vertical-align: top;
        .spacing-normal();
      }
    }
    a {
      display: block;
      position: relative;
    }
    i, img {
      .inline-block();
      margin-top: 12px;
    }
  }
}

.dropdown {
  .dropdown-toggle {
    position: relative;
    background: @color-primary;
    border-color : @color-primary;
    color: @white;
    padding-right: 2.5rem !important;
  }
  .title {
    overflow: ellipsis;
    white-space: nowrap;
  }
  i {
    color: @white;
    position: absolute;
    right: 18px;
    top: 50%;
    .translate(0, -50%);
  }
  + hr {
    margin-top: 15px;
  }
}
.dropdown-menu {
  background-color: @white;
  border: none;
  font-size: 1rem;
  overflow: hidden;
  margin-top: 1px;
  padding: 10px 0;
  border-radius: 0.5rem;
  >li>a,
  >li>span {
    .fontBold;
    color: @color-primary;
    cursor: pointer;
    display: block;
    line-height: 1.375em;
    padding: 12px 35px 12px 30px;
  }
}

@media (min-width: @bp-small) {
  .main-nav {
    span {
      display: block;
    }
    .no-flexbox & {
      i, span {
        .inline-block();
        vertical-align: middle;
      }
      span {
        margin-top: 12px;
      }
    }
  }
}
