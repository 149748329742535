//
// Scaffolding
// --------------------------------------------------

* {
  .box-sizing(border-box);
}
*:before,
*:after {
  .box-sizing(border-box);
}

html {
  background-color: @body-bg !important;
  font-size: @base-font-size;
  line-height: @base-line-height;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  touch-action: manipulation;
  &.lt-ie9 {
    height: auto;
  }
}

body {
  background-color: @body-bg;
  color: @text-color;
  .fontRegular;
  font-size: @base-font-size;
  line-height: @base-line-height;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
}

body, html {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#root {
  width: 100%;
  height: 100%;
}

#container {
  width: 100%;
  height: 100%;
}

.view-container {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: 100%;
  .flexbox & {
    .flex-display(flex);
    .flex-direction(column);
  }
  .touchevents & {
    //padding-bottom: 44px;
  }

  .page-template-info.is-platform-ios & {
    -webkit-overflow-scrolling: auto;
  }

  > * {
    //-webkit-transform: translate3d(0, 0, 0);
  }
}

#busy {
  background-color: transparent;
  //display: none;
  margin: 0 auto;
  max-width: 1024px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 50%;
  .opacity(0);
  .transition(all .35s ease-in-out);
  .translate(-50%, 0);
  body.is-busy & {
    background-color: @color-quinary;
    //display: block;
    z-index: 51;
    .opacity(1);
  }
  i {
    color: @color-secondary;
    font-size: 3rem;
    height: 60px;
    position: absolute;
    left: 50%;
    top: 50%;
    text-align: center;
    padding: 2px;
    width: 60px;
    .transform-origin(0 0);
    .translate(-50%, -50%);
  }
}

#content {
  margin: 0 auto;
  max-width: 1024px;
  overflow: hidden;
  position: relative;
  width: 100%;
  z-index: 50;

  > div {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;

    transform: translate3d(0, 0, 0);
    transition: transform 0.333s cubic-bezier(0.4, 0.0, 0.6, 1.0), opacity 0.2s ease-in-out;
    will-change: transform;

    &.animate-in.animate-forward {
      transform: translate3d(100%, 0, 0);
    }

    &.animate-in.animate-backward {
      transform: translate3d(-100%, 0, 0);
    }

    &.animate-out.animate-forward {
      transform: translate3d(-100%, 0, 0);
    }

    &.animate-out.animate-backward {
      transform: translate3d(100%, 0, 0);
    }

    &.busy {
      opacity: 0.33;
      pointer-events: none;
    }
  }
}

#content {
  min-height:~"calc(100vh - 60px)";
  &.full-height {
    min-height: 100vh;
  }
}

#subcontent > .view-container {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  &.visible {
    opacity: 1;
  }
}

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a {
  color: @link-color;
  .transition(all .15s ease);
  &:hover,
  &:focus {
    color: @link-color-hover;
  }
  &:focus {
    outline: 0;
  }
}

figure {
  margin: 0;
}

img {
  vertical-align: middle;
}

.img-responsive {
  .img-responsive();
}
.img-circle {
  .border-radius(50%);
}

.element-invisible {
  display: none;
}

@media (min-width: 600px) {
  .view-container {
    .touchevents & {
      //padding-bottom: 70px;
    }
  }
}

#offline {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000;

  .loading-container {
    .flex-display(flex);
    .flex-align-items(center);
    .flex-justify-content(center);
    height: 100vh;
  }

  .message {
    .fontRegular;
    padding: 20px;
    margin: 40px;
    background-color: @white;
  }

  body.is-offline & {
    display: block;
  }
}
