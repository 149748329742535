//
// Progress
// --------------------------------------------------

.progress {
  background-color: #eee;
  height: 20px;
  margin-bottom: 0;
  .border-radius(10px);
  .box-shadow(none);
  .progress-bar {
    background-color: @color-secondary;
    .box-shadow(none);
  }
}

.progress-trophy {
  background: url(../images/svg/trophy.svg) no-repeat center center;
  background-size: contain;
  width: 40px;
  height: 40px;
}