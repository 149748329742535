//
// Header
// --------------------------------------------------

.region-header {
  margin: 0 auto;
  max-width: 1024px;
  //overflow: hidden;
  //position: relative;
  width: 100%;
  z-index: 50;
}

#header {
  background: url(../images/bg-tile.png) bottom left;
  padding: 9px 0;
  position: relative;
  .is-home & {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.3);
    }
  }
  &.no-padding {
    padding: 0;
  }
  .flexbox & {
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
  }
  .col-xs-12 {
    .flex-align-items(center);
    .flex-display(flex);
    .flex-justify-content(space-between);
    .no-flexbox & {
      display: block;
      .actions-wrapper {
        float: left;
        + .actions-wrapper {
          float: right;
        }
      }
    }
  }
  .actions-wrapper {
    display: flex;
    justify-content: flex-end;
    .text {
      color: @white;
      display: none;
      margin-right: 10px;
    }

    .dropdown {
      margin-left: 0.5rem;
    }

    @media(max-width: @bp-x-small) {
      .dropdown {
        .dropdown-toggle {
          padding-right: 2rem !important;

          i {
            font-size: 0.6rem;
          }
        }
      }

      .btn {
        font-size: 0.8rem;
        padding: 12px 15px;
      }
  
      .btn-icon {
        padding: 0;
  
        i {
          font-size: 1.25rem;
          margin-right: 8px;
        }
      } 
    }
  }
  .btn-transparent {
    color: @white;
  }
}

@media (min-width: @bp-x-small) {
  #header {
    .actions-wrapper {
      .text {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}
