//
// Article
// --------------------------------------------------

.mainContent {
  .page-title {
    font-size: 1.6470588235rem;
    text-align: center;
  }
  // REGISTREREN
  &.register {
    text-align: center;
    margin-bottom: 6em;
    .page-title {
      font-size: 1.7647058824rem;
      margin-bottom: 0.4333333333em;
    }
    p {
      line-height: 1.4705882353em;
      margin-left: auto;
      margin-right: auto;
      max-width: 500px;
    }
  }
  // INLOGGEN & WACHTWOORD VERGETEN
  &.login,
  &.password {
    text-align: center;
    .page-title {
      margin-bottom: 1em;
    }
  }
  &.kraak-noot {
    padding-top: 5px;
    text-align: center;
  }
  // THEMAS
  &.thema {
    .thema-head {
      padding-top: 15px;
      padding-bottom: 15px;
      text-align: center;
      .title {
        color: @white;
        font-size: 1.6470588235rem;
        margin-bottom: 0.3928571429em;
        i[class^="icon-"],
        i[class*=" icon-"] {
          margin-right: 10px;
        }
      }
      p {
        color: @white;
        margin-bottom: 10px;
      }
      .thema-status {
        div {
          margin-left: auto;
          margin-right: auto;
          max-width: 270px;
          padding: 10px 0;
          &.is-active {
            background-color: @color-quinary;
            .border-radius(50px);
            i[class^="icon-"],
            i[class*=" icon-"] {
              color: @color-secondary;
              font-size: 1.2rem;
              margin-right: 6px;
            }
            span {
              color: @color-secondary;
              font-size: 1rem;
              font-style: italic;
              text-transform: lowercase;
            }
          }
          i[class^="icon-"],
          i[class*=" icon-"] {
            font-size: 1rem;
          }
        }
      }
    }
  }
  // NOOT VAN DE DAG
}

@media (min-width: @bp-small) {
  .mainContent {
    // THEMAS
    &.thema {
      padding-top: 20px;
      padding-bottom: 20px;
    }
  }
}
